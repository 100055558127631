export const sortDate = (a, b) => {
  return new Date(a.date) - new Date(b.date)
}

/**
 * Checks if the given date string is in the past.
 * @param {string} dateString - The ISO date string (e.g., "2025-02-19T15:52:00.000Z").
 * @returns {boolean} - Returns true if the date is in the past, false otherwise.
 */
export const isPastDate = (dateString) => {
  if (!dateString) return false
  const now = new Date()
  const targetDate = new Date(dateString)

  if (isNaN(targetDate.getTime())) {
    return false
  }

  return targetDate < now
}
